<template>
    <div>
        <div class="content" v-if="hasMetaTopContent">
            <hr />
            <CollapsedContent>
                <div v-html="meta.content_top" />
            </CollapsedContent>
            <hr />
        </div>

        <div ref="rootAnchor" class="anchor" />

        <CatalogControls class="my content" :show-top-content="!hasMetaTopContent" />

        <div id="catalog-main" class="content">
            <div v-if="showErrorMessage" class="content text-center">
                <div class="alert-danger">
                    <span> {{ $t('_catalog._error.unableToLoad') }}</span>
                    <span v-html="$t('errorContactUsHtml')" />
                </div>
            </div>
            <div
                v-else-if="!products || (products.hits || []).length === 0"
                class="content text-center"
            >
                <img
                    :src="`${$scope.CDN_SHARED}/images/site/catalog/art-no-results.png?auto=compress,format&q=65&w=100&fit=crop`"
                    :alt="$t('noResults')"
                />
                <p>
                    {{ $t('_catalog._error.noResults') }}
                </p>
            </div>
            <div v-else class="pos-relative">
                <div :class="{ busy: loading }" :style="{ opacity: showSpinner ? 0 : null }">
                    <ProductList
                        :current-page="products.current_page"
                        :event-name="listEventName"
                        :gender="gender"
                        :item-options="options"
                        :per-page="parseInt(products.per_page)"
                        :products="products.hits"
                        :selected-filters="selected"
                        list-class="product-list product-list-4 product-list-gap-x-2 product-list-gap-y-4"
                    >
                        <template
                            v-if="!$scope.IS_REGULAR_CUSTOMER"
                            v-slot:below-image="{ product }"
                        >
                            <button
                                class="btn btn-sm btn-light btn-wide hidden-md-down mb-2"
                                @click.stop.prevent="productToBuy = product"
                            >
                                Comprar
                            </button>
                        </template>
                    </ProductList>
                    <CatalogPagination v-if="options && options.enablePagination" class="mt" />
                </div>
                <div
                    v-if="loading"
                    v-show="showSpinner"
                    class="pos-absolute pos-top pos-left w-full"
                >
                    <Spinner />
                </div>
            </div>
        </div>

        <div v-if="hasMetaBottomContent" class="content">
            <hr />
            <div v-html="meta.content_bottom"></div>
        </div>
    </div>
</template>

<script>
    import CatalogPagination from './CatalogPagination';
    import ProductList from '../common/ProductList';
    import CatalogControls from './CatalogControls';
    import Spinner from '../../../submodules/src/components/feedback/spinner/Spinner.vue';
    import { catalogStore } from '../../common/store/catalog-store';
    import CollapsedContent from '../common/container/CollapsedContent';

    export default {
        name: 'Catalog',

        components: {
            CollapsedContent,
            CatalogControls,
            ProductList,
            CatalogPagination,
            Spinner,
        },

        data() {
            return {
                storeState: catalogStore.state,
                showErrorMessage: false,
                productToBuy: null,
                showSpinner: false,
            };
        },

        watch: {
            meta: {
                deep: true,
                handler(val) {
                    if (!val) return;

                    if (val.title) {
                        document.title = val.title;
                    }
                    if (val.description) {
                        let el = document.querySelector("meta[name='description']");

                        if (!el) {
                            el = document.createElement('meta');
                            el.setAttribute('name', 'description');
                            document.head.append(el);
                        }

                        el.setAttribute('content', val.description);
                    }
                    if (val.canonical) {
                        let el = document.querySelector("link[rel='canonical']");

                        if (!el) {
                            el = document.createElement('link');
                            el.setAttribute('rel', 'canonical');
                            document.head.append(el);
                        }
                        el.setAttribute('href', location.origin + val.canonical);
                    }
                },
            },

            selected: {
                immediate: true,
                handler(to) {
                    if (to) {
                        window.dataLayer.push({
                            'product-category-id': to.category ? to.category : to.type_id,
                            'product-category-name': to.category_name
                                ? to.category_name
                                : to.partner_name
                                ? to.partner_name
                                : to.type_name,
                            'product-gender-category': to.gender_name ? to.gender_name : '',
                            google_tag_params: {
                                ecomm_pagetype: 'category',
                                ecomm_category: to.category_name
                                    ? to.category_name
                                    : to.partner_name
                                    ? to.partner_name
                                    : to.type_name,
                            },
                            'product-list-criteo':
                                ((this.products || {}).hits || []).length > 0
                                    ? this.products.hits.slice(0, 4).map((p) => p.id + '')
                                    : [],
                        });
                    }
                },
            },

            loading(to) {
                if (to) {
                    this.scrollToTop();
                    setTimeout(() => {
                        if (this.loading) {
                            this.showSpinner = true;
                        }
                    }, 3000);
                } else {
                    this.showSpinner = false;
                }
            },
        },

        computed: {
            products() {
                return this.storeState.products;
            },

            meta() {
                return this.storeState.meta;
            },

            options() {
                return this.storeState.options;
            },

            filters() {
                return this.storeState.filters;
            },

            selected() {
                return this.storeState.selected;
            },

            loading() {
                return this.storeState.loading;
            },

            gender() {
                const gender = this.selected ? this.selected.gender : null;
                return gender === 'M' ? 1 : gender === 'F' ? 2 : 0;
            },

            listEventName() {
                if (this.selected) {
                    return (
                        (this.selected.partner_name ? this.selected.partner_name + ' - ' : '') +
                        (this.selected.type_main_url ? this.selected.type_main_url : '') +
                        (this.selected.type_name ? ' - ' + this.selected.type_name : '') +
                        (this.selected.category_name ? ' - ' + this.selected.category_name : '') +
                        (this.selected.model_name ? ' - ' + this.selected.model_name : '')
                    );
                } else {
                    return null;
                }
            },

            hasMetaTopContent() {
                return !this.isMetaContentEmpty(this.meta.content_top);
            },

            hasMetaBottomContent() {
                return !this.isMetaContentEmpty(this.meta.content_bottom);
            },
        },

        created() {
            if (this.products.per_page != '36' && this.products.per_page) {
                const url = new URL(window.location.href);
                url.searchParams.set('per_page', this.products.per_page);
                window.history.replaceState('', '', url.toString());
            }
        },

        methods: {
            scrollToTop() {
                cr$.byEl(this.$refs.rootAnchor).scrollIntoView(500);
            },

            isMetaContentEmpty(metaContent) {
                if (!metaContent || metaContent.length === 0) {
                    return true;
                }

                const fakeEl = document.createElement('div');
                fakeEl.innerHTML = metaContent;
                return fakeEl.textContent.length === 0;
            },
        },
    };
</script>
